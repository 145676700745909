var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.errorMessage !== ""
        ? _c("div", [_vm._v(_vm._s(_vm.errorMessage))])
        : !_vm.isEmailConfirmed
        ? _c("div", [_vm._v(_vm._s(_vm.$t("account.confirmEmail.checking")))])
        : !_vm.isPasswordSet
        ? _c(
            "app-form",
            {
              attrs: {
                id: "set-password-form",
                submitAction: _vm.setPassword,
                submitSuccessAction: () => (_vm.isPasswordSet = true),
                validationOptions: _vm.validationOptions
              }
            },
            [
              _c("div", [
                _vm._v(_vm._s(_vm.$t("account.confirmEmail.setPassword")))
              ]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-textbox", {
                    attrs: {
                      id: "newPassword",
                      placeholder: _vm.$t("account.newPassword"),
                      type: "password"
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-textbox", {
                    attrs: {
                      id: "newPasswordConfirm",
                      placeholder: _vm.$t("account.newPasswordConfirm"),
                      type: "password"
                    },
                    model: {
                      value: _vm.newPasswordConfirm,
                      callback: function($$v) {
                        _vm.newPasswordConfirm = $$v
                      },
                      expression: "newPasswordConfirm"
                    }
                  })
                ],
                1
              ),
              _c("app-form-buttons", {
                staticClass: "login",
                attrs: {
                  addSubmitButton: true,
                  submitButtonText: "account.confirmEmail.submit"
                }
              })
            ],
            1
          )
        : _c("div", [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("account.confirmEmail.setPasswordSuccess")))
            ]),
            _c("div", { staticStyle: { "padding-top": "8px" } }, [
              _c("a", { attrs: { href: "/account/login" } }, [
                _vm._v(_vm._s(_vm.$t("account.confirmEmail.goToLogin")))
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }